import React, { useEffect, useRef } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'
import DayJsUtils from '@date-io/dayjs'
import { ErrorBoundary } from 'react-error-boundary'
import ru from 'dayjs/locale/ru'
import en from 'dayjs/locale/en'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import {
  theme,
  Snackbar,
  BuildInfo,
  config,
  NextQueryParamProvider,
  ErrorBoundaryComponent,
  useLocale
} from '@plandi/common'
import { Provider as ReduxProvider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { ReactQueryDevtools } from 'react-query/devtools'
import NProgress from 'nprogress'
import Router from 'next/router'

import { store } from 'src/store'
import 'nprogress/nprogress.css'
import '../styles/globals.css'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import { components } from '@plandi/common/src/generated'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

type WorkaroundAppProps = AppProps & {
  err: Record<string, unknown>
  translationData: components['schemas']['Translate']
}

export default function App(props: WorkaroundAppProps) {
  const { Component, pageProps, err } = props

  const locales = useLocale()

  const queryClientRef = useRef<null | QueryClient>()

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient()
  }
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content={`width=device-width, initial-scale=1.0,
          maximum-scale=1.0, user-scalable=0`}
        />
        {(config.isProd || config.isStage) && (
          <meta name="yandex-verification" content="ec5ffea16906ef39" />
        )}
        {(config.isProd || config.isStage) && (
          <meta name="mailru-domain" content="6Dr4szGlxTOW1V7t" />
        )}
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicon-16x16.png"
          sizes="16x16"
        />
        <link rel="icon" href="/assets/favicon.ico" />
        <link rel="icon" href="/assets/favicon.svg" />
        {/*INFO: uncomment if needed*/}
        {/*{config.isProd && (*/}
        {/*  <script src="//code-eu1.jivosite.com/widget/TWVsZUI6qE" async />*/}
        {/*)}*/}
      </Head>
      <MuiThemeProvider theme={theme}>
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={pageProps.dehydratedState}>
            <ReduxProvider store={store}>
              <SnackbarProvider maxSnack={5}>
                <NextQueryParamProvider>
                  <MuiPickersUtilsProvider
                    locale={locales === 'ru' ? ru : en}
                    utils={DayJsUtils}
                  >
                    <ErrorBoundary fallback={<ErrorBoundaryComponent />}>
                      <CssBaseline />
                      <BuildInfo />
                      <Snackbar />
                      <Component {...pageProps} err={err} />
                    </ErrorBoundary>
                  </MuiPickersUtilsProvider>
                </NextQueryParamProvider>
              </SnackbarProvider>
            </ReduxProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </Hydrate>
        </QueryClientProvider>
      </MuiThemeProvider>
    </>
  )
}
